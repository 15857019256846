import Logo from '../assets/logo-achemed-branco-vertical.png'
import './css/LoadingBlock.css'

function AchemedLoadingBlock() {
    return (
        <div className='loading-block'>
            <img alt="logo" src={Logo} />
        </div>
    )
}

export default AchemedLoadingBlock;