import React from 'react'
import MaskedInput from 'react-text-mask'
function InputDate({ value, onChange }) {

    const mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];


    return (
        <MaskedInput inputMode='numeric' mask={mask} placeholder='00/00/0000' className="form-control" value={value} onChange={value => {
            onChange(value.target.value)
        }}></MaskedInput>)
}

export default InputDate