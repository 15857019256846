import React from 'react'
import MaskedInput from 'react-text-mask'
function InputPhoneMobile({ value, onChange }) {

    const mask = ['(', /\d/, /\d/, ')', ' ',/\d/,' ', /\d/, /\d/, /\d/, /\d/,' ', '-', ' ', /\d/, /\d/, /\d/, /\d/];


    return (
        <MaskedInput inputMode='numeric' mask={mask} placeholder='(99) 9 9999 - 9999' className="form-control" value={value} onChange={value => {
            onChange(value.target.value)
        }}></MaskedInput>)
}

export default InputPhoneMobile