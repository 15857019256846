import QRCode from "react-qr-code";
import Android from '../assets/btn-android-download.png';
import iOS from '../assets/btn-iphone-download.png';

import './css/AchemedCTA.css';

function AchemedCTA() {
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.videoinkless&hl=pt_BR'
  const appleStoreLink = 'https://apps.apple.com/br/app/achemed/id1588143608'

  return (
    <>
      <h3 className="my-5 text-center text-blue">Baixe o app Achemed</h3> 
  
      <div className="col-12 text-center d-flex justify-content-around flex-column">
        <div className="android">
          <h4 className="my-4 text-center text-blue">Android</h4>
          <p className="text-blue d-block d-md-none">Pressione o botão do <strong>Google Play</strong> abaixo</p>
          <p className="text-blue d-none d-md-block">Pressione o botão do <strong>Google Play</strong> abaixo ou leia o <strong>QR Code</strong></p>

          <div className="d-flex justify-content-evenly align-items-center">
          <a href={playStoreLink} target="_blank" rel="noopener noreferrer" title="Abrir Google Play" className="my-4">
            <img src={Android} alt="Baixar app para Android" width={180} />
          </a>

          <div className="bg-light border-blue p-qr-code pt-0 rounded-4 d-none d-md-block">
            <QRCode className="mt-4"
              size={150}
              value={playStoreLink}
            />
            </div>
          </div>
        </div>

        <hr className="text-blue table-group-divider"></hr>

        <div className="iphone">
          <h4 className="my-4 text-center text-blue">Iphone</h4>
          <p className="text-blue d-block d-md-none">Pressione o botão da <strong>Apple Store</strong> abaixo</p>
          <p className="text-blue d-none d-md-block">Pressione o botão da <strong>Apple Store</strong> abaixo ou leia o <strong>QR Code</strong></p>

          <div className="d-flex justify-content-evenly align-items-center">
            <a href={appleStoreLink} target="_blank" rel="noopener noreferrer" title="Abrir Apple Store" className="my-4">
              <img src={iOS} alt="Baixar app para iOS" width={180} />
            </a>

            <div className="bg-light border-blue-2 p-qr-code pt-0 rounded-4 d-none d-md-block">
              <QRCode className="mt-4 d-none d-md-block"
                  size={150}
                  value={appleStoreLink}
                />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default AchemedCTA;